<script>
import Firebase from "../firebase_settings/index.js";
import { getFirestore, orderBy, startAt, endAt, collection, query, where, doc, getDoc, getDocs, getCountFromServer, limit } from "firebase/firestore";
import { ref as fbRef, getDownloadURL } from "firebase/storage";
import { useRoute } from "vue-router";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ref } from 'vue'
import index from '/config/algolia'
import config from '/config/config.js'
import municipalities from '/data/municipalities.json';
import geohash from "ngeohash"; 
import * as geofire from "geofire-common"; 

const storage = Firebase.storage

  export default {
    name: "serviceTop",
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    watch: {
    '$route'() {
      // ルートが変わったときに実行されるメソッド
      this.$router.go();
    }
    },

    async mounted() {

      this.updateItemsToShow(); // 初期表示で適用
      window.addEventListener("resize", this.updateItemsToShow); // リサイズ監視

      window.addEventListener('scroll', this.handleScroll);
      document.title = this.spotName + 'TOP - さあ歴史を見つけよう BYGO';
      this.setMetaDescription('TOP' +' | BYGO（バイゴー）は、まだ知らないまちの歴史を見つけ、みんなでシェアするサービスです。近くの史跡や歴史の情報を探す、歴史の情報を記録する、歴史の情報をシェアすることができます');
      this.setCanonicalURL(`https://bygo.jp/${this.$route.params.areaCode}/${this.$route.params.locationId}`);
      this.setPageTitleAndDescription();

      try {  
        await this.loadMapKitScript(); // MapKitスクリプトの読み込みを待機
        await this.getLocationAndArea(); // nearestAreaが設定されるまで待機

        this.fetchNearbyLocations();

      if (this.nearestArea) {
          if (this.locationIds.length > 0) {
          await this.getRelatedData(this.locationIds); // locationIds を使って関連データを取得
        
        console.log("関連データの取得が完了しました"+this.locationIds);
        } else {
          console.warn("関連データを取得する locationIds がありません。");
        }
      } else {
        console.error("nearestArea が設定されていません。getLocations を実行できません。");}
      } catch (error) {
        console.error("エラーが発生しました:", error);}
    },

    data() {
      return {

        query: '',
        hits: [],
        showSearchResults: false,
        locationIds: [], // locationIds を追加

        isSearchActive: false, // 検索結果を表示するかどうかのフラグ
        spotName: "",
        spotNameKana: "",
        spotDescription: "",
        spotWiki: "",
        spotGeopointLat: "",
        spotGeopointLon: "",
        objectArray: [],
        locationArray: [],
        map: null,
        isScrolling: false,
        scrollTimeout: null,
        isLoading: true,

        nearestArea: null,
        error: null,

        itemsToShow: 4, // デフォルト表示数

        center: { latitude: 35.6895, longitude: 139.6917 }, // 東京の中心座標
          radiusInM: 3000, // 検索半径（メートル単位）
          locations: [], // 検索結果を格納する配列
      }
    },

  methods: {

    // クリック時にナビゲーションを実行
    navigate(url) {
      this.$router.push(url);
    },

    setMetaDescription(content) {
          const meta = document.querySelector('meta[name=description]') || document.createElement('meta');
          meta.setAttribute('name', 'description');
          meta.setAttribute('content', content);
          document.head.appendChild(meta);
        },

    setCanonicalURL(url) {
          const link = document.querySelector('link[rel="canonical"]') || document.createElement('link');
          link.setAttribute('rel', 'canonical');
          link.setAttribute('href', url);
          document.head.appendChild(link);
        },

    setPageTitleAndDescription() {
        // ページタイトルと説明を設定するロジックを追加
        console.log('ページタイトルと説明を設定しました');
      },

    //検索
    search() {

      this.showSearchResults = true;  // 検索結果が表示されるように設定

      const filters = 'state:true';
      index.search(this.query, { filters }).then(({ hits }) => {
        this.hits = hits;
      });
    },

    hideSearch() {
      this.showSearchResults = false;  // マウスが抜けた時に非表示
    },

    //----------
    //post投稿数をデバイスで制御
    updateItemsToShow() {
      const width = window.innerWidth;
      if (width >= 1200) {
        this.itemsToShow = 4.1; // デスクトップ表示
      } else if (width >= 1000) {
        this.itemsToShow = 3.1; // タブレット表示
      } else if (width >= 700) {
        this.itemsToShow = 2.2; // モバイル表示
      } else {
        this.itemsToShow = 1.2;
      }
    },

    //近くのスポット → getRelatedData
    async fetchNearbyLocations() {
      const db = getFirestore();
      const { latitude, longitude } = this.center;
      const radius = this.radiusInM;

      // locationIds を初期化
      this.locationIds = [];
      console.log("fetchNearbyLocations を開始");

  try {
    // 1. クエリ範囲を計算
    const bounds = geofire.geohashQueryBounds([latitude, longitude], radius);
      console.log("計算されたクエリ範囲:", bounds);

      const promises = bounds.map(([start, end], index) => {
  console.log(`クエリ範囲 (${index}):`, start, end);
  const locationsRef = collection(db, "locations");
  const q = query(
    locationsRef,
    where("state", "==", true), // `state` が必須条件
    orderBy("geohash"),        // geohash をソート
    startAt(start),            // 範囲開始
    endAt(end),           // 範囲終了
    limit(10)
  );

  return getDocs(q);
});

    // 3. 全てのクエリを並列で実行
    const snapshots = await Promise.all(promises);
    console.log("取得したスナップショット数:", snapshots.length);

    // 4. 結果を統合
    const results = snapshots.flatMap((snapshot) =>
      snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
      console.log("統合された結果:", results);

    // クエリ結果が空の場合の処理
    if (results.length === 0) {
      console.warn("近くのスポットが見つかりませんでした。");
      return;
    }

    // 5. locationArray を初期化
    this.locationArray = [];

    // 6. 各結果について画像を取得
    const imagePromises = results.map(async (location) => {
      const imagePath = location.image?.["200"] || "";
      const starsRef = fbRef(storage, `images200*200/${imagePath}`);
      try {
        const imgUrl = await getDownloadURL(starsRef);
        this.locationArray.push({
          title: location.name,
          img_url: imgUrl,
          area_code: location.area_code,
          id: location.id,
          url: `/${location.area_code}/${location.id}`,
        });
      } catch (error) {
        // 画像取得に失敗した場合はデフォルト画像を設定
        this.locationArray.push({
          title: location.name,
          img_url: "https://storage.googleapis.com/history-d8428.appspot.com/base/no%20image.png",
          area_code: location.area_code,
          id: location.id,
          url: `/${location.area_code}/${location.id}`,
        });
      }
      // locationIds に追加
      this.locationIds.push(location.id);
    });

    // 全ての画像取得処理を完了するまで待機
    await Promise.all(imagePromises);
    //console.log("最終的な locationIds:", this.locationIds);

    // locationIds を使用して関連データを取得
    if (this.locationIds.length > 0) {
      console.log("取得した locationIds:", this.locationIds);
      await this.getRelatedData(this.locationIds);
    } else {
      console.warn("関連データを取得する locationIds がありません。");
    }
  } catch (error) {
    console.error("Error fetching nearby locations:", error);
  }
    },
  
    //postチェックインデータ done
    async getRelatedData(locationIds) {
      this.objectArray = [];
      const db = Firebase.db;

      try {
        // locationIds を 15 件以下のチャンクに分割
        const chunkedIds = [];
        for (let i = 0; i < locationIds.length; i += 15) {
          chunkedIds.push(locationIds.slice(i, i + 15));
        }

        // 各チャンクでクエリを実行
        const queryPromises = chunkedIds.map(async (idsChunk) => {
          const q = query(
            collection(db, "checkIn"),
            where("post", ">", ""),
            limit(10) // 各チャンクを条件に使用
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        });

        // 全てのクエリを並列実行して結果を取得
        const allResults = (await Promise.all(queryPromises)).flat();

        if (allResults.length === 0) {
          this.objectArray.push({
            title: "",
            content: "関連データがありません",
            name: "no name",
            userImageURL: 'https://storage.googleapis.com/history-d8428.appspot.com/base/UserIcon_200.png'
          });
          return;
        }

        // 各結果について詳細データを取得
        for (const master of allResults) {
          const locationId = master.locationId;
          const userId = master.userId;

          // Locationのタイトルを取得
          let title = "";
          try {
           const locationDocRef = doc(db, "locations", locationId);
           const locationDoc = await getDoc(locationDocRef);
           if (locationDoc.exists()) {
             title = locationDoc.data().name;
             //console.log("Location title:", title);
           }
          } catch (error) {
           console.error("Error getting location title:", error);
          }

          // Userの名前と画像を取得
          let name = "非公開";
          let userImageURL = 'https://storage.googleapis.com/history-d8428.appspot.com/base/UserIcon_200.png'; // Firebaseのデフォルト画像
          try {
            const userDocRef = doc(db, "accounts", userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              name = userDoc.data().name || "非公開";
              const uImage = userDoc.data().uImage;
              if (uImage) {
                try {
                  const starsRef = fbRef(storage, 'userIcon/' + uImage);
                  userImageURL = await getDownloadURL(starsRef);
                } catch (imageError) {
                  console.error("Error fetching user image, using default:", imageError);
                  userImageURL = 'https://storage.googleapis.com/history-d8428.appspot.com/base/UserIcon_200.png'; // Firebaseのデフォルト画像
                }
              }
            }
          } catch (error) {
            console.error("Error getting user name or image:", error);
            userImageURL = 'https://storage.googleapis.com/history-d8428.appspot.com/base/UserIcon_200.png'; // Firebaseのデフォルト画像
          }

          // オブジェクト配列に追加
          this.objectArray.push({
            title: title,
            content: master.post,
            name: name,
            userImageURL: userImageURL
          });
        }
      } catch (error) {
        console.error("関連データ取得エラー:", error);
      }
    },
    //----------

    //位置情報別の処理 → getNearestArea
    getLocationAndArea() {
      return new Promise((resolve, reject) => {
        const defaultLat = 35.681236;
        const defaultLong = 139.767125;

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              this.nearestArea = this.getNearestArea(latitude, longitude);
              console.log("取得した nearestArea:", this.nearestArea); // ログ追加
              resolve();
            },
            (error) => {
              console.error("位置情報の取得に失敗しました0:", error.message);
              this.nearestArea = this.getNearestArea(defaultLat, defaultLong);
              this.error = "位置情報の取得に失敗したため、東京駅を基準に最寄りのエリアコードを取得しました。";
              resolve();
            }
            );
        } else {
          console.error("位置情報がサポートされていません");
          this.nearestArea = this.getNearestArea(defaultLat, defaultLong);
          this.error = "このブラウザは位置情報に対応していません。東京駅を基準に最寄りのエリアコードを取得しました。";
          resolve();
        }
      });
    },

    // 最寄りのエリアコードを取得 → haversineDistance
    getNearestArea(lat, lon) {
      let nearest = null;
      let minDistance = Infinity;

      municipalities.forEach((municipality) => {
        const distance = this.haversineDistance(
          lat,
          lon,
          municipality.latitude,
          municipality.longitude
          );

        if (distance < minDistance) {
          minDistance = distance;
          nearest = municipality;
        }
      });

      return nearest;
    },
  
    // ハバーサイン距離計算
    haversineDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // 地球の半径 (km)
      const toRad = (deg) => (deg * Math.PI) / 180;

      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // 距離を返す
    },

    //----------
    //map　→ initializeMap
    async loadMapKitScript() {

       // MapKit JSスクリプトのURL
      const mapKitScriptUrl = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
      const route = useRoute();
      const db = Firebase.db

      // スクリプトタグを作成
      const script = document.createElement('script');
      script.src = mapKitScriptUrl;

      const self = this;

      script.onload = () => {
        //console.log("Script loaded successfully");

        // 現在位置の取得
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
            // 現在位置の座標を使用して初期化
              const lat = position.coords.latitude;
              const long = position.coords.longitude;
              self.initializeMap("Current Location", lat, long);
            },
            (error) => {
              // 位置情報取得が失敗した場合は東京駅を使用
              console.error("位置情報の取得に失敗しました1: ", error.message);
              const defaultLat = 35.681236; // 東京駅の緯度
              const defaultLong = 139.767125; // 東京駅の経度
              self.initializeMap("Tokyo Station", defaultLat, defaultLong);
            }
            );
        } else {
          console.error("位置情報の取得に失敗しました2: ", error.message);
          const defaultLat = 35.681236; // 東京駅の緯度
          const defaultLong = 139.767125; // 東京駅の経度
          self.initializeMap("Tokyo Station", defaultLat, defaultLong);
        }
      };
      document.head.appendChild(script);
    },
    
    // MapKitの初期化 → createMap
    initializeMap(name,lat, long) {
      // MapKitの初期化
      mapkit.init({
        authorizationCallback: function(done) {
        // ここにMapKit JSのキーを設定              
          done(config.secretToken);
        },
      });
      this.createMap(name, lat, long);
    },

    //ピンの追加　→ fetchNearbySpotsWithGeohash
    async createMap(name, lat, long) {
      const initialCoordinate = new mapkit.Coordinate(lat, long);
      const initialSpan = new mapkit.CoordinateSpan(0.01, 0.01);
      const initialRegion = new mapkit.CoordinateRegion(initialCoordinate, initialSpan);

      const map = new mapkit.Map('map', { region: initialRegion });

      try {
        const spots = await this.fetchNearbySpotsWithGeohash(lat, long);
        console.log("マップに追加するスポット:", spots);

        spots.forEach((spot) => {
          console.log("スポットデータ確認:", spot);

          // `geopoint` を展開して緯度・経度を取得
          const latitude = spot.geopoint?._lat || spot.latitude;
          const longitude = spot.geopoint?._long || spot.longitude;

          if (latitude && longitude) {
            const spotCoordinate = new mapkit.Coordinate(latitude, longitude);
            const pin = new mapkit.MarkerAnnotation(spotCoordinate, { title: spot.name });
            map.addAnnotation(pin);
            console.log(`ピン追加: ${spot.name} (${latitude}, ${longitude})`);
          } else {
            console.warn("スポットの位置データが不足しています:", spot);
          }
        });

        console.log("スポットのピンを地図に追加しました");
      } catch (error) {
        console.error("スポットの取得または地図への追加に失敗しました:", error);
      }
    },

    //geohashを取得 →　done
    async fetchNearbySpotsWithGeohash(lat, long) {
      const bounds = geofire.geohashQueryBounds([lat, long], 300);
      const db = Firebase.db;
      const collectionRef = collection(db, "locations");

      const spots = [];
      for (const b of bounds) {
        console.log(`クエリ範囲: ${b[0]} - ${b[1]}`);
        const q = query(
          collectionRef,
          where("geohash", ">=", b[0]),
          where("geohash", "<=", b[1]),
          limit(10)
        );

        try {
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const data = doc.data();

            // `geopoint` を展開して `latitude` と `longitude` を設定
            const latitude = data.geopoint?._lat;
            const longitude = data.geopoint?._long;

            spots.push({
              id: doc.id,
              ...data,
              latitude,
              longitude,
            });
          });
        } catch (error) {
          console.error(`クエリエラー (${b[0]} - ${b[1]}):`, error);
        }
      }

      console.log("周辺スポット（整形済み）:", spots);
      return spots;
    }
  }
}
</script>

<template>
  <main id="main_all">
    <div class="container main_flame">
      <div>
            <div id="search_area_top">
              <input v-model="query" @input="search" id="search_box_top" placeholder="検索するテキストを入力...">
              <ul id="search_area_ul"  @mouseleave="hideSearch" v-show="showSearchResults">
                <li v-for="item in hits" :key="item.objectID" class="search_list_li">
                  <router-link :to="{ path:`/${item.areaCode}/${item.objectID}`}">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>

          <div id="map"><h3 class="sub-caption">地図から見つける</h3></div>

          <h3 class="sub-caption">このスポットのシェア</h3>
          <!--<Carousel :itemsToShow="4" > -->
            <Carousel :itemsToShow="itemsToShow">

              <Slide v-for="slide in objectArray" :key="objectArray">
                <div class="carousel__item description_flame">

                  <div class="carousel_title_area">
                    <h3 class="carousel_title">{{ slide.title }}</h3>
                    <p class="carousel_text">{{ slide.content }}</p>
                  </div>
                  <div class="carousel_content">

                    　
                  </div>
                </div>
                <div class="carousel_accounts">
                  <img :src="slide.userImageURL" class="carousel_user_image" />
                  <p class="carousel_name">{{ slide.name }}</p>
                </div>

              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>

            <h3 class="sub-caption">近くのスポット</h3>

            <Carousel  :itemsToShow="1.5" >
              <Slide v-for="slide in locationArray" :key="locationArray" @click="navigate(slide.url)" class="test" >
                <div class="carousel_item_spot">

                  <img :src="slide.img_url" class="img_carousel" />
                  <p class="carousel_name">{{ slide.title }}</p>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
                <!--<Pagination />-->
              </template>
            </Carousel>

          </div>
          <div v-if="!isScrolling" id="app_install">
            <div id="logo"></div>
            <div id="install_button">
              <a id="install_text" href="https://apps.apple.com/jp/app/bygo-%E3%81%95%E3%81%82%E6%AD%B4%E5%8F%B2%E3%82%92%E8%A6%8B%E3%81%A4%E3%81%91%E3%82%88%E3%81%86/id1568929579">アプリをインストール</a>
            </div>  
          </div>
        </div>

      </main>

    </template>